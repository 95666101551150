// import React from 'react';
import { Box, MenuItem, FormControl, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { changingTeamSelection } from 'features/team/teamActions';

export default function ({ selectionWrapper = {}, selection = {} }) {
    const { teams, selectedTeam } = useSelector((s) => s.team);

    const handleChange = (event) => {
        const id = event.target.value;
        if (id) {
            changingTeamSelection(id)();
        }
    };
    // console.log({ teams });
    return (
        <Box sx={{ minWidth: 120, mb: 2.5 }}>
            {teams?.length ? (
                <FormControl fullWidth sx={{ ...selectionWrapper }}>
                    <Select
                        sx={{ ...selection }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedTeam?._id || ''}
                        onChange={handleChange}
                    >
                        {teams?.map?.((team) => (
                            <MenuItem key={team?._id} value={team?._id}>
                                {team.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                ''
            )}
        </Box>
    );
}

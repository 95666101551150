/* eslint-disable consistent-return */
import axios from 'utils/axios';
import { dispatch, getState } from 'app/store';
import {
    hasError,
    loading,
    getMentionSetting,
    updateMentionSetting,
    mentionSettingCretedOrUpdated,
    // mentionInit,
    mentionSettingUpdateLoading
} from './mentionSlice';
import errorMsgHelper from 'utils/errorMsgHelper';
// import { updateProjectData } from 'features/project/projectActions';
import { addingAiNewModel, deletingAiNewModel, updatedaiModelSetter } from 'features/ai-model/aiModelActions';
import { verifiyAccessToken } from 'features/auth/authActions';

export const mentionClear = () => () => {
    // dispatch(mentionInit());
    dispatch(hasError(null));
};
export const mentionErrorClear = () => () => {
    dispatch(hasError(null));
};
export const mentionSetter = (vals) => () => {
    dispatch(getMentionSetting(vals));
};

export const getMentionSettingAPI = () => async () => {
    try {
        const {
            team: { selectedTeam },
            auth: { accessToken }
        } = getState();
        const token = await verifiyAccessToken(accessToken);
        // const { _id: teamMemberId } = selectedTeam?.teamMembers?.find?.((item) => item.userId === user._id) || {};
        const { _id: teamMemberId } = selectedTeam?.teamMember || {};
        dispatch(loading(true));
        const { data } = await axios.get(`mention-settings`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-br-team-id': selectedTeam?._id || '',
                'x-br-team-owner-id': selectedTeam?.userId || '',
                'x-br-team-member-id': teamMemberId || ''
            }
        });
        mentionSetter(data)();
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        dispatch(loading(false));
    }
};

export const updatedMentionSetter = (vals) => () => {
    dispatch(updateMentionSetting(vals));
};

export const mentionSettingCretedOrUpdatedStatus = (vals) => () => {
    dispatch(mentionSettingCretedOrUpdated(vals));
};

export const updateMentionSettingAPI =
    ({ data = {} }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken }
            } = getState();
            const token = await verifiyAccessToken(accessToken);
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};
            dispatch(mentionSettingUpdateLoading(true));
            const { data: respData } = await axios.post(`mention-settings`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            if (respData?.model) {
                const { actionType } = respData?.model;
                if (actionType === 'add') {
                    addingAiNewModel({ item: respData?.model })();
                } else if (actionType === 'update') {
                    updatedaiModelSetter({ item: respData?.model })();
                } else if (actionType === 'delete') {
                    deletingAiNewModel({ item: respData?.model })();
                }
            }
            updatedMentionSetter(respData)();
            // const { platforms } = data;
            // updateProjectData({ item: { platforms } })();
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            dispatch(mentionSettingUpdateLoading(false));
        }
    };

export const generateMentionReplyAPI = async ({ id, projectId, data = {} }) => {
    const {
        team: { selectedTeam },
        auth: { accessToken }
    } = getState();
    const token = await verifiyAccessToken(accessToken);
    const { _id: teamMemberId } = selectedTeam?.teamMember || {};

    const response = await axios.post(`/mentions/${id}/generate-reply/${projectId}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-br-team-id': selectedTeam?._id || '',
            'x-br-team-owner-id': selectedTeam?.userId || '',
            'x-br-team-member-id': teamMemberId || ''
        }
    });
    return response.data;
};

export const updateReplyOfMentionAPI = async ({ id, data = {} }) => {
    const {
        team: { selectedTeam },
        auth: { accessToken }
    } = getState();
    const token = await verifiyAccessToken(accessToken);
    const { _id: teamMemberId } = selectedTeam?.teamMember || {};

    const response = await axios.put(`/mentions/${id}/update-reply`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-br-team-id': selectedTeam?._id || '',
            'x-br-team-owner-id': selectedTeam?.userId || '',
            'x-br-team-member-id': teamMemberId || ''
        }
    });
    return response.data;
};

export const loadMoreMentionAPI = async ({ data = {} }) => {
    const {
        team: { selectedTeam },
        auth: { accessToken }
    } = getState();
    const token = await verifiyAccessToken(accessToken);
    const { _id: teamMemberId } = selectedTeam?.teamMember || {};

    const response = await axios.post(`mentions/load-more`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-br-team-id': selectedTeam?._id || '',
            'x-br-team-owner-id': selectedTeam?.userId || '',
            'x-br-team-member-id': teamMemberId || ''
        }
    });
    return response.data;
};

export const deleteMentionAPI = async ({ id, projectId }) => {
    const {
        team: { selectedTeam },
        auth: { accessToken }
    } = getState();
    const token = await verifiyAccessToken(accessToken);
    const { _id: teamMemberId } = selectedTeam?.teamMember || {};

    const response = await axios.delete(`/mentions/${id}/project/${projectId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-br-team-id': selectedTeam?._id || '',
            'x-br-team-owner-id': selectedTeam?.userId || '',
            'x-br-team-member-id': teamMemberId || ''
        }
    });
    return response.data;
};

export const getAllMentionsOfProjectAPI = async ({ projectId, query }) => {
    const {
        team: { selectedTeam },
        auth: { accessToken }
    } = getState();
    const token = await verifiyAccessToken(accessToken);
    const { _id: teamMemberId } = selectedTeam?.teamMember || {};

    const response = await axios.get(`mentions/projects/${projectId}${query ? `?${new URLSearchParams(query)}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-br-team-id': selectedTeam?._id || '',
            'x-br-team-owner-id': selectedTeam?.userId || '',
            'x-br-team-member-id': teamMemberId || ''
        }
    });
    return response.data;
};

import { Box, Modal } from '@mui/material';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { useSelector } from 'react-redux';
import {
    // clearingError,
    getTeamProjectsAPI,
    updateMentionFetchStatusChangerOfProject
} from 'features/project/projectActions';
import React from 'react';
// import { toast } from 'react-toastify';
import NewProject from 'views/BizReply/projects/NewProject';
import socket from 'socket';
import useAuth from 'hooks/useAuth';
import { updateSucceedReport } from 'features/report/reportActions';
import { getPromptsAPI } from 'features/prompt/promptActions';
import { rolePermissionsAPI, teamMemberToTeamAPI } from 'features/team/teamActions';
import { toast } from 'react-toastify';
// import { getMentionSettingAPI } from 'features/mention/mentionActions';
// import TeamList from 'ui-component/Team/TeamList';

const Header = () => {
    const {
        project: { showProjectCreateModal, project },
        team: { selectedTeam, rolePermissions, loading: teamLoading, teams },
        prompt: { loading }
    } = useSelector((state) => state);
    const { dbUser, user } = useAuth();
    React.useEffect(() => {
        if (teamLoading || !teams?.length) {
            return;
        }
        if (selectedTeam?._id || '') {
            getTeamProjectsAPI({ teamId: selectedTeam?._id || '' })();
            if (!loading) {
                getPromptsAPI()();
            }
        }
        if (!rolePermissions?.length) {
            rolePermissionsAPI();
        }
    }, [selectedTeam?._id, teamLoading]);

    React.useEffect(() => {
        socket.connect();
        if (project?._id) {
            const projectMentionStatus = `mentionsStatus:${project?._id}`;
            console.log(`Socket is connected mentionsStatus`, projectMentionStatus);
            socket.on(projectMentionStatus, ({ message: { mentionsStatus }, purposeName }) => {
                // "succeed"
                updateMentionFetchStatusChangerOfProject({ _id: project._id, mentionsStatus, purposeName })();
                console.log({ mentionsStatus, purposeName });
            });
        }
    }, [project?._id]);

    const userId = dbUser?._id || user?.id;
    // console.log({ userId });
    React.useEffect(() => {
        socket.connect();
        if (userId) {
            const reportStatus = `report:${userId}`;
            // console.log(`Socket is connected pdf status---------------------`, reportStatus);
            socket.on(
                reportStatus,
                ({
                    message: {
                        userId,
                        reportId,
                        item: { pdfUrl, pageCount }
                    }
                }) => {
                    // console.log('==========Alhamdu lillah');
                    updateSucceedReport({ _id: reportId, userId, pageCount, pdfUrl })();
                }
            );

            const invitationConfirmationStatus = `invitationConfirmationStatus:${userId}`;
            socket.on(invitationConfirmationStatus, ({ message: { status, success } }) => {
                if (status === 200 && success) {
                    teamMemberToTeamAPI({ selectCollaborationTeam: true });
                    toast.success(`Invitation has been accepted!`);
                }
            });
        }
    }, [userId]);

    return (
        <>
            {/* <TeamList /> */}
            <Box sx={{ marginRight: 1, flexGrow: 1, pl: 2 }} />
            <Box sx={{ marginRight: 1, flexGrow: 1, pl: 2 }} />
            <ProfileSection />
            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
            <Modal
                open={showProjectCreateModal}
                sx={{ '.MuiBox-root:nth-child(3)': { minWidth: { md: '600px', sx: 'unset', sm: '500px' } } }}
            >
                <NewProject />
            </Modal>
        </>
    );
};

export default Header;

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { INVITATION_CONFIRMATION_PATH, LOGIN_PATH, REGISTER_PATH, DASHBOARD_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, logout } = useAuth();
    const navigate = useNavigate();

    const location = useLocation();
    const { search, pathname } = location;

    // console.log(pathname, 'AuthGuard', location);
    useEffect(() => {
        if (isLoggedIn) {
            if (pathname === INVITATION_CONFIRMATION_PATH) {
                console.log(
                    pathname === INVITATION_CONFIRMATION_PATH,
                    '------------------------ INVITATION_CONFIRMATION_PATH AuthGuard--------------------------',
                    location
                );
                const newsignup = new URLSearchParams(search).get('newsignup');

                if (newsignup) {
                    console.log('INVITATION_CONFIRMATION_PATH login ', { newsignup });
                    (async () => {
                        await logout();
                        navigate(`${newsignup ? REGISTER_PATH : LOGIN_PATH}${search}`, { replace: true });
                    })();
                } else navigate(`${DASHBOARD_PATH}${search}`, { replace: true });
            }
        } else if (pathname === INVITATION_CONFIRMATION_PATH) {
            const newsignup = new URLSearchParams(search).get('newsignup');
            navigate(`${newsignup ? REGISTER_PATH : LOGIN_PATH}${search}`, { replace: true });
        } else if (!isLoggedIn) {
            navigate(LOGIN_PATH, { replace: true });
        }
        // DASHBOARD_PATH
    }, [isLoggedIn]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;

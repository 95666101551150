/* eslint-disable consistent-return */
import axios from 'utils/axios';
import { dispatch, getState } from 'app/store';
import {
    hasError,
    loading,
    aiModelUpdateLoading,
    aiModelCretedOrUpdated,
    getAiModel,
    getAiModels,
    updateAiModel,
    mentionInit,
    singleAiModelloading,
    addAiModel,
    deleteAiModel
} from './aiModelSlice';
import errorMsgHelper from 'utils/errorMsgHelper';
import { verifiyAccessToken } from 'features/auth/authActions';

export const mentionClear = () => () => {
    dispatch(mentionInit());
};

export const addingAiNewModel = (vals) => () => {
    dispatch(addAiModel(vals));
};
export const updatedaiModelSetter = (vals) => () => {
    dispatch(updateAiModel(vals));
};

export const deletingAiNewModel = (vals) => () => {
    dispatch(deleteAiModel(vals));
};
// single model
export const aiModelSetter = (vals) => () => {
    dispatch(getAiModel(vals));
};

export const getAiModelAPI = (_, id) => async () => {
    try {
        const {
            team: { selectedTeam },
            auth: { accessToken }
        } = getState();
        const { _id: teamMemberId } = selectedTeam?.teamMember || {};
        const token = await verifiyAccessToken(accessToken);
        dispatch(singleAiModelloading(true));
        const { data } = await axios.get(`ai-models/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-br-team-id': selectedTeam?._id || '',
                'x-br-team-owner-id': selectedTeam?.userId || '',
                'x-br-team-member-id': teamMemberId || ''
            }
        });
        aiModelSetter(data)();
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        dispatch(singleAiModelloading(false));
    }
};
// Single user Models
export const aiModelsSetter = (vals) => () => {
    dispatch(getAiModels(vals));
};
export const getAiModelsAPI = () => async () => {
    try {
        const {
            team: { selectedTeam },
            auth: { accessToken }
        } = getState();
        const { _id: teamMemberId } = selectedTeam?.teamMember || {};
        const token = await verifiyAccessToken(accessToken);
        dispatch(loading(true));
        const { data } = await axios.get(`ai-models`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-br-team-id': selectedTeam?._id || '',
                'x-br-team-owner-id': selectedTeam?.userId || '',
                'x-br-team-member-id': teamMemberId || ''
            }
        });
        aiModelsSetter(data)();
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        dispatch(loading(false));
    }
};

export const AiModelCretedOrUpdatedStatus = (vals) => () => {
    dispatch(aiModelCretedOrUpdated(vals));
};

export const updateAiModelAPI =
    ({ data = {}, id }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken }
            } = getState();
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};
            const token = await verifiyAccessToken(accessToken);
            dispatch(aiModelUpdateLoading(true));
            const { data: respData } = await axios.put(`ai-models/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            updatedaiModelSetter(respData)();
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            dispatch(aiModelUpdateLoading(false));
        }
    };

// export const addingKeywords = (data) => () => {
//     dispatch(createKeywords(data));
// };

/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
// import { fetchAllteamMembers } from './teamMemberActions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    teamMembers: [],
    updateTeamMemberProjectsRoleLoading: false,
    teamMemberAddLoading: false,
    loading: false,
    teamMemberDeleteLoading: false,
    teamMemberAdded: false,
    teamMemberDeleted: false,
    teamMemberInvitationLoading: false,
    teamMemberInvitated: false,
    teamMemberInvitationResendLoading: false,
    teamMemberResent: false,
    updateTeamMemberProjectAndRolePermissionLoading: false,
    teamMemberProjectAndRolePermissionUpdated: false,
    resentTeamMemberId: ''
};

export const getItem = ({ findBy = '_id', findKey = '', datas = [] }) => {
    if (!findBy || !findKey) return null;
    let data = null;
    for (const item of datas) {
        if (item[findBy] === findKey) {
            data = item;
            break;
        }
    }
    return data;
};

const teamMemberSlice = createSlice({
    name: 'teamMember',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        clearError(state) {
            state.error = null;
        },

        teamMemberInit(state) {
            Object.keys(initialState).map((k) => {
                state[k] = initialState[k];
            });
        },

        // fetchteamMembers(state, action) {
        //     const items = action.payload.items;
        //     const { search } = window.location;
        //     const qs = new URLSearchParams(search);
        //     const findKey = qs.get('dp');
        //     const getDefault = getItem({ findKey, datas: items });
        //     state.teamMembers = items;
        //     const firstItem = getDefault || items?.[0];
        //     if (firstItem) {
        //         state.teamMember = firstItem;
        //         state.selectedPlatform = firstItem.platforms?.[0];
        //         // if (firstItem.suggestedKeywords?.length) {
        //         //     state.suggestedKeywords = firstItem.suggestedKeywords;
        //         // }
        //     }
        //     state.loading = false;
        // },
        addTeamMember(state, { payload }) {
            // state.teamMembers = [...(state.teamMembers || []), payload.item];
            state.teamMembers.push(payload.item);
            state.teamMemberInvitationLoading = false;
            state.teamMemberInvitated = true;
        },

        teamMemberInvitationLoading(state, { payload }) {
            state.teamMemberInvitationLoading = payload;
        },

        teamMemberInvitated(state, { payload }) {
            state.teamMemberInvitated = payload;
        },

        teamMemberInvitationResendLoading(state, { payload }) {
            state.teamMemberInvitationResendLoading = payload;
        },

        teamMemberResent(state, { payload }) {
            state.teamMemberResent = payload;
        },
        resentTeamMemberId(state, { payload }) {
            state.resentTeamMemberId = payload;
        },

        teamMemberAddLoading(state, { payload }) {
            state.teamMemberAddLoading = payload;
        },

        teamMemberAdded(state, { payload }) {
            state.teamMemberAdded = payload;
        },

        loading(state, { payload }) {
            state.loading = payload;
        },

        teamMembers(state, { payload }) {
            state.teamMembers = payload.items || [];
            state.loading = false;
        },

        teamMemberDeleteLoading(state, { payload }) {
            state.teamMemberDeleteLoading = payload;
        },

        deleteTeamMember(state, { payload }) {
            state.teamMembers = state.teamMembers.filter((item) => item._id !== payload);
            state.teamMemberDeleted = true;
        },

        teamMemberDeleted(state, { payload }) {
            state.teamMemberDeleted = payload;
        },

        updateTeamMemberProjectAndRolePermission(state, { payload }) {
            state.teamMembers = payload;
            state.updateTeamMemberProjectAndRolePermissionLoading = false;
        },

        updateTeamMemberProjectAndRolePermissionLoading(state, { payload }) {
            state.updateTeamMemberProjectAndRolePermissionLoading = payload;
        },

        teamMemberProjectAndRolePermissionUpdated(state, { payload }) {
            state.teamMemberProjectAndRolePermissionUpdated = payload;
        },

        updateTeamMemberProjectsRole(state, { payload }) {
            const { rolePermissionId, teamMemberId, rolePermissions = [] } = payload;
            state.teamMembers = state.teamMembers.map((item) => {
                if (teamMemberId === item._id) {
                    // const { projectWiseUserRole } = item;
                    item.projectWiseUserRole = item.projectWiseUserRole.map((im) => {
                        im.rolePermission = rolePermissions.find((rp) => rp._id === rolePermissionId);
                        return im;
                    });
                }
                return item;
            });
            state.updateTeamMemberProjectsRoleLoading = false;
        },

        updateTeamMemberProjectsRoleLoading(state, { payload }) {
            state.updateTeamMemberProjectsRoleLoading = payload;
        }
    }
});

export const {
    hasError,
    clearError,
    teamMemberInit,
    // fetchteamMembers,
    teamMembers,
    deleteTeamMember,
    updateTeamMemberProjectsRole,
    updateTeamMemberProjectsRoleLoading,
    addTeamMember,
    teamMemberAddLoading,
    teamMemberDeleteLoading,
    loading,
    updateTeamMemberProjectAndRolePermission,
    updateTeamMemberProjectAndRolePermissionLoading,
    teamMemberInvitationLoading,
    teamMemberInvitated,
    teamMemberInvitationResendLoading,
    teamMemberResent,
    teamMemberProjectAndRolePermissionUpdated,
    resentTeamMemberId
} = teamMemberSlice.actions;

export default teamMemberSlice.reducer;

/* eslint-disable consistent-return */
import axios from 'utils/axios';
import { dispatch } from 'app/store';
import { settingAccessToken } from './authSlice';
import { jwtDecode } from 'jwt-decode';

export const accessTokenSetter = (v) => () => {
    dispatch(settingAccessToken(v));
};

export const uidToAccessToken = async (expiredToken) => {
    try {
        const { data } = await axios.get('user/firebase-access-token', {
            headers: {
                Authorization: `Bearer ${expiredToken}`
            }
        });
        return data?.accessToken;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const verifiyAccessToken = async (accessToken) => {
    try {
        if (accessToken) {
            const { exp } = jwtDecode(accessToken);
            if (Date.now() > exp * 1000) {
                const newToken = await uidToAccessToken(accessToken);
                accessTokenSetter(newToken)();
                return newToken;
            }
            return accessToken;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
};

/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
import { useState, createContext, useEffect, useReducer } from 'react';
import { initializeApp } from 'firebase/app';
import { toast, ToastContainer } from 'react-toastify';
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    getAuth,
    // GoogleAuthProvider,
    // SignInWithPopup,
    onAuthStateChanged,
    onIdTokenChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updatePassword
} from 'firebase/auth';
import { ReactSession } from 'react-client-session';
// action - state management
import { LOGIN, LOGOUT } from 'features/actions';
import accountReducer from 'features/accountReducer';
// project imports
import Loader from 'ui-component/Loader';
import {
    FIREBASE_API,
    ONBOARDING_PATH,
    APPSUMO_PATH,
    EMAIL_VERIFIED_PATH,
    INVITATION_CONFIRMATION_PATH,
    REGISTER_PATH,
    LOGIN_PATH,
    DASHBOARD_PATH
} from 'config';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'utils/axios';
import { useDispatch } from 'react-redux';
import { settingAccessToken, settingUser } from 'features/auth/authSlice';
import { projectClear } from 'features/project/projectActions';
import { getMySubscriptionAPI, subscriptionClear, subsctriptionSetter } from 'features/subscription/subscriptionActions';
import { getSelfPromptsAPI } from 'features/prompt/promptActions';
import { mentionSetter } from 'features/mention/mentionActions';
// import { getAllReportsAPI } from 'features/report/reportActions';
import { getAiModelsAPI } from 'features/ai-model/aiModelActions';
import stringToObject from 'utils/stringToObject';
import { rolePermissionsAPI, setTeamsData, teamMemberToTeamAPI } from 'features/team/teamActions';
// import { firebase } from 'googleapis/build/src/apis/firebase';

ReactSession.setStoreType('localStorage');

initializeApp(FIREBASE_API);

const auth = getAuth();

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};
let isRegister = false;
// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [dbUser, setDbUser] = useState({});
    // const [isRegister, setIsRegister] = useState(false);
    const [isExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [generalError, setGeneralError] = useState('');
    const [authProviders, setAuthProviders] = useState([]);
    const [accessToken, setAccessToken] = useState(ReactSession.get('token') || '');
    const reduxDispatch = useDispatch();
    // console.log({ isRegister });

    const changePassword = async ({ oldPassword, password }) => {
        await signInWithEmailAndPassword(auth, dbUser.email, oldPassword);
        return updatePassword(auth.currentUser, password);
    };

    async function refreshToken() {
        const user = auth?.currentUser;
        // console.log('Current user:', user); // Debug
        if (user) {
            try {
                const token = await user.getIdToken(); // Force refresh
                console.log('Token refreshed:', token); // Debug
                setDbUser((prevUser) => ({ ...prevUser, token }));
            } catch (error) {
                console.error('Error refreshing token:', error); // Debug
            }
        }
    }
    // refreshToken(); //

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(auth, async (user) => {
            // console.log('Id token changed:', user); // Debug
            if (user) {
                refreshToken();
            }
        });

        // Cleanup
        return () => {
            unsubscribe(); // Unsubscribe from the listener when the component unmounts
        };
    }, [auth, onIdTokenChanged]);

    useEffect(() => {
        rolePermissionsAPI();
    }, []);

    async function getAccessToken() {
        try {
            const accessToken = dbUser.token;
            if (accessToken) {
                const { exp } = jwtDecode(dbUser?.token || '');
                if (Date.now() > exp * 1000) {
                    const user = auth?.currentUser;
                    if (user) {
                        try {
                            const token = await user.getIdToken(); // Force refresh
                            setDbUser((prevUser) => ({ ...prevUser, token }));
                            reduxDispatch(settingAccessToken(token));
                            return token;
                        } catch (error) {
                            console.error('Error refreshing token:', error); // Debug
                            return accessToken;
                        }
                    }
                    return accessToken;
                }
                return accessToken;
            }
            return '';
        } catch (e) {
            return '';
        }
    }
    //   getAccessToken();

    // firebase.auth().onIdTokenChanged((user) => {
    //     if (user) {
    //         refreshToken()
    //         // const token = await user.getIdToken();
    //     }
    //   });

    async function logout() {
        const logedOut = await signOut(auth);
        setDbUser({});
        projectClear()();
        subscriptionClear()();
        return logedOut;
    }

    const handleUseEffect = async () => {
        const queryParams = new URLSearchParams(search);
        const code = queryParams.get('code');
        console.log('logout();', code);
        if (pathname === APPSUMO_PATH) {
            console.log('logout();', pathname);
            await logout();
            console.log('logout();', 'logout called now redirect');
            navigate(`${APPSUMO_PATH}?code=${code}`);
            console.log('logout(); after();', pathname);
        } else if (pathname === INVITATION_CONFIRMATION_PATH) {
            const newsignup = new URLSearchParams(search).get('newsignup');

            if (newsignup) {
                console.log('INVITATION_CONFIRMATION_PATH login ', { newsignup });
                await logout();
                navigate(`${newsignup ? REGISTER_PATH : LOGIN_PATH}${search}`, { replace: true });
            } else navigate(`${DASHBOARD_PATH}${search}`, { replace: true });
        }

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = user.accessToken;
                reduxDispatch(settingAccessToken(token));
                const email = user.email;
                const uid = user.uid;
                setAccessToken(token);
                ReactSession.set('token', token);
                setAuthProviders(user.providerData.map((item) => item.providerId));
                // console.log({ email, uid, token });
                axios
                    .post(
                        `user/get-user-by-email-and-uid`,
                        { email, uid, pathname, invitation_param: search },
                        {
                            headers: { Authorization: `Bearer ${token}` }
                        }
                    )
                    .then(async ({ data }) => {
                        data.user.token = token;
                        const userData = data.user;
                        console.log(userData, 'data user');
                        setDbUser(userData);
                        reduxDispatch(settingUser(userData));
                        setTeamsData({ userId: userData._id, items: data.teams, selectCollaborationTeam: true })();
                        getSelfPromptsAPI()();

                        // setTimeout(() => {
                        getMySubscriptionAPI(token)();
                        // getMentionSettingAPI(token)();
                        // getAllReportsAPI(token)();
                        getAiModelsAPI(token)();
                        // }, 1500);

                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    id: user.uid,
                                    email: user.email,
                                    name: user.displayName || user.name,
                                    image: user.photoURL
                                }
                            }
                        });
                        if (search) {
                            const params = new URLSearchParams(search);
                            if (params.get('email') && params.get('teamId')) {
                                console.log({ pathname });
                                teamMemberToTeamAPI({ selectCollaborationTeam: true });
                                navigate(pathname, { replace: true });
                                // window.location.reload();
                            }
                        }
                        // if (isRegister) setIsRegister(false);
                    })
                    .catch(async (e) => {
                        console.log('error', e?.response?.data || e.message, { isRegister });
                        if (isRegister === false) {
                            console.log({ isRegister }, 'isRegisterrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
                            const app_sumo_license_key = localStorage.getItem('app_sumo_license_key');
                            if (app_sumo_license_key && Object.keys(stringToObject(app_sumo_license_key)).length) {
                                localStorage.clear();
                                localStorage.setItem('app_sumo_license_key', app_sumo_license_key);
                            } else {
                                localStorage.clear();
                            }
                            await logout();
                            signOut(auth);
                        }
                        if (e?.response?.status !== 404) {
                            console.log(e, '===error==========');
                            toast(`User not found. Please check the details and try again.`, {
                                autoClose: 2500,
                                type: 'warning'
                            });
                        }
                    });
            } else {
                dispatch({
                    type: LOGOUT
                });
                const app_sumo_license_key = localStorage.getItem('app_sumo_license_key');
                if (app_sumo_license_key && Object.keys(stringToObject(app_sumo_license_key)).length) {
                    localStorage.clear();
                    localStorage.setItem('app_sumo_license_key', app_sumo_license_key);
                } else {
                    localStorage.clear();
                }
                signOut(auth);
            }
        });
    };
    useEffect(() => {
        if (isLoading) return;
        handleUseEffect();
    }, []);

    // using this function to login by firebase, and fetch logged in users data from database.
    const firebaseEmailPasswordSignIn = ({ email, password }) => {
        setIsLoading(true);
        isRegister = true;
        console.log({ isRegister }, email);

        signInWithEmailAndPassword(auth, email, password)
            .then(async (result) => {
                const token = await result._tokenResponse.idToken;
                const UID = result.user.uid;
                const user = {
                    UID,
                    email
                };
                const userObj = {
                    uid: UID,
                    email,
                    ...stringToObject(localStorage.getItem('app_sumo_license_key') || ''),
                    pathname,
                    invitation_param: search
                };
                console.log({ token, user });
                setAccessToken(token);
                ReactSession.set('token', token);
                axios
                    .post(`user/get-user-by-email-and-uid`, userObj, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                    .then(async ({ data }) => {
                        if (data.subscription) {
                            subsctriptionSetter({ item: data.subscription })();
                        }
                        if (data.mentionSetting) {
                            mentionSetter({ item: data.mentionSetting })();
                        }
                        //
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    id: data?.user.UID,
                                    email: user.email,
                                    name: user.displayName || user.name,
                                    image: user.photoURL
                                }
                            }
                        });

                        data.user.token = token;
                        setDbUser(data.user);
                        setIsLoading(false);
                        localStorage.removeItem('app_sumo_license_key');
                        // return navigate(DASHBOARD_PATH);
                    })
                    .catch(async () => {
                        await logout();
                        setIsLoading(false);
                        isRegister = false;
                        toast('User not found. Please check the details and try again.', {
                            autoClose: 2500,
                            type: 'warning'
                        });
                    });
            })
            .catch((e1) => {
                console.log(JSON.stringify(e1), '===');
                const { code, message } = e1;
                const msg = code === 'auth/user-not-found' || code === 'auth/wrong-password' ? `Credentials doesn't match` : message;
                toast(msg, {
                    autoClose: 2500,
                    type: 'warning'
                });
                setIsLoading(false);
                isRegister = false;
            });
    };

    const firebaseGoogleLoginOrSignup = async () => {
        setIsLoading(true);
        isRegister = true;
        const googleProvider = new GoogleAuthProvider();
        const data = await signInWithPopup(auth, googleProvider);
        const body = {
            email: data?.user?.email,
            name: data?.user?.displayName,
            UID: data?.user?.uid,
            ...stringToObject(localStorage.getItem('app_sumo_license_key') || ''),
            pathname,
            invitation_param: search
        };

        const token = data?.user?.accessToken;
        setAccessToken(token);
        ReactSession.set('token', token);
        console.log(data?.user, body, token);

        axios
            .post(`user/create-user-or-get-user`, body, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(({ data, status }) => {
                console.log(data?.user);
                data.user.token = token;
                // console.log({ data, user: data.user });
                setDbUser(data.user);

                setTeamsData({ userId: data.user._id, items: data.teams || [] })();
                if (data.subscription) {
                    subsctriptionSetter({ item: data.subscription })();
                }
                if (data?.mentionSetting) {
                    mentionSetter({ item: data.mentionSetting })();
                }
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            id: data.user.uid,
                            email: data.user.email,
                            name: data.user.name,
                            image: data.user.photoURL || ''
                        }
                    }
                });
                setIsLoading(false);
                localStorage.removeItem('app_sumo_license_key');
                if (status === 201) return navigate(ONBOARDING_PATH);
            })
            .catch(async (eRR) => {
                const app_sumo_license_key = localStorage.getItem('app_sumo_license_key');
                if (app_sumo_license_key && Object.keys(stringToObject(app_sumo_license_key)).length) {
                    localStorage.clear();
                    localStorage.setItem('app_sumo_license_key', app_sumo_license_key);
                } else {
                    localStorage.clear();
                }
                if (isRegister) {
                    isRegister = false;
                }
                await logout();
                setIsLoading(false);
                setGeneralError(eRR.response?.data?.message || eRR.message || 'Something went wrong');
            });
    };

    // using this function to register user at firsbase and create user at out database.
    const firebaseRegisterWithOTP = (values) => {
        const vals = values;
        const { email, name, password } = vals;
        // console.log({ email, name, password }, 'firebaseRegisterWithOTP');
        // return;
        setIsLoading(true);
        isRegister = true;

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (result) => {
                const token = await result._tokenResponse.idToken;
                const UID = result.user.uid;
                const body = {
                    ...stringToObject(localStorage.getItem('app_sumo_license_key') || ''),
                    pathname,
                    invitation_param: search,
                    UID,
                    email,
                    name
                };

                // console.log(body, 'axios body');
                axios
                    .post(`user/create-user`, body, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                    .then(({ data }) => {
                        data.user.token = token;
                        console.log({ data, user: data.user });
                        setDbUser(data.user);

                        setTeamsData({ userId: data.user._id, items: data.teams || [] })();
                        if (data.subscription) {
                            subsctriptionSetter({ item: data.subscription })();
                        }
                        if (data?.mentionSetting) {
                            mentionSetter({ item: data.mentionSetting })();
                        }
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    id: result.user.uid,
                                    email: result.user.email,
                                    name: result.user.displayName || result?.user?.name,
                                    image: result.user.photoURL
                                }
                            }
                        });
                        setIsLoading(false);
                        localStorage.removeItem('app_sumo_license_key');
                        return navigate(EMAIL_VERIFIED_PATH);
                        // return navigate(ONBOARDING_PATH);
                    })
                    .catch(async (eRR) => {
                        const app_sumo_license_key = localStorage.getItem('app_sumo_license_key');
                        if (app_sumo_license_key && Object.keys(stringToObject(app_sumo_license_key)).length) {
                            localStorage.clear();
                            localStorage.setItem('app_sumo_license_key', app_sumo_license_key);
                        } else {
                            localStorage.clear();
                        }
                        isRegister = false;
                        if (UID) {
                            await auth.currentUser.delete();
                        }
                        await logout();
                        setIsLoading(false);
                        setGeneralError(eRR.response?.data?.message || eRR.message || 'Something went wrong');
                    });
            })
            .catch((error) => {
                let msg = 'Something wont wrong';
                if (error.code === 'auth/email-already-in-use') msg = 'User already register';
                setGeneralError(msg);
                setIsLoading(false);
                isRegister = false;
            });
    };

    const resetPassword = async (email) => {
        await sendPasswordResetEmail(email);
    };

    const updateProfile = async ({ name }) => {
        const token = await getAccessToken();
        const { data } = await axios.put(
            `user`,
            { name },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        setDbUser((p) => ({ ...p, name, displayName: name }));
        // dispatch({
        //     type: UPDATE_USER,
        //     payload: {
        //         data: {
        //             name
        //         }
        //     }
        // });
        return data;
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseEmailPasswordSignIn,
                logout,
                resetPassword,
                updateProfile,
                dbUser,
                setDbUser,
                isExpired,
                accessToken,
                setAccessToken,
                isLoading,
                generalError,
                setGeneralError,
                firebaseRegisterWithOTP,
                auth,
                firebaseGoogleLoginOrSignup,
                getAccessToken,
                changePassword,
                authProviders
            }}
        >
            <ToastContainer position="top-right" autoClose={2000} />
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
